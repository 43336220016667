import { toast, Toaster as Sonner } from 'sonner'

import { AnimatedCheckmark } from './AnimatedCheckmark'
import { Icon } from './Icon'
import { Spinner } from './Spinner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group flex flex-col items-center"
      position="bottom-center"
      icons={{
        success: <AnimatedCheckmark size="xs" />,
        loading: <Spinner size="sm" />,
        error: (
          <Icon
            name="alert-triangle"
            size="lg"
          />
        ),
      }}
      toastOptions={{
        unstyled: true,
        duration: 4000,
        classNames: {
          success: 'bg-primary text-white',
          error: 'bg-error-600 text-error-100',
          loading: 'bg-primary text-white',
          warning: 'bg-warning-100 text-warning-600',
          info: 'bg-white text-gray-600',
          toast:
            'shadow-lg text-sm p-4 flex items-center gap-2 rounded-md min-w-88',
          title: 'font-medium',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  )
}

export { toast, Toaster }

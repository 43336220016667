import { cn } from '@hapstack/common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { AnimatePresence, motion } from 'framer-motion'

const checkmarkVariants = cva('flex items-center justify-center rounded-full', {
  variants: {
    size: {
      xxs: 'size-3',
      xs: 'size-4',
      sm: 'size-6',
      md: 'size-8',
      lg: 'size-10',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

const pathClasses = cva('text-white', {
  variants: {
    size: {
      xxs: 'size-2',
      xs: 'size-3',
      sm: 'size-4',
      md: 'size-6',
      lg: 'size-8',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

type CheckmarkProps = VariantProps<typeof checkmarkVariants>

export const AnimatedCheckmark = ({ size }: CheckmarkProps) => {
  return (
    <AnimatePresence>
      <motion.div
        className={cn('bg-accent', checkmarkVariants({ size }))}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: 'easeIn', type: 'tween' }}
      >
        <svg
          className={cn(pathClasses({ size }))}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={4}
        >
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{
              ease: 'easeOut',
              type: 'tween',
              delay: 0.4,
              duration: 0.3,
            }}
            // strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </motion.div>
    </AnimatePresence>
  )
}
